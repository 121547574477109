import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth-guard';

const routes: Routes = [
  /* {
    path: 'u',
    loadChildren: './admin/admin.module#AdminModule',
    canActivate: [ AuthGuard ]
  }, */
  {
    path: 'u',
    loadChildren: './subscriber/subscriber.module#SubscriberModule',
    canActivate: [ AuthGuard ]
  },
  {
    path: 'info',
    loadChildren: './info/info.module#InfoModule'
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: '**',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false, useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
