import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { SubscribePromise } from './subscribe-promise';

export class BehaviorObservable {

  private __bhvrSbjct: BehaviorSubject<any>;
  Data: any;

  public Cast: Observable<any> = new Observable<any>();

  constructor() {
    this.init();
  }

  init() {
    this.__bhvrSbjct = new BehaviorSubject<any>(this.Data)
    this.Cast = this.__bhvrSbjct.asObservable();
  }

  nextCast(_data) {
    this.Data = _data;
    this.__bhvrSbjct.next(this.Data);
  }

}


@Injectable({
  providedIn: 'root'
})
export class RouteService extends SubscribePromise {

  private __activateRoute: ActivatedRoute;
  private __router: Router;

  Params: BehaviorObservable = new BehaviorObservable();
  QueryParams: BehaviorObservable = new BehaviorObservable();
  RouterEvents: BehaviorObservable = new BehaviorObservable();

  constructor(
  ) {
    super();

    this.init();
  }
  init() {
  }
  initRouter(_router: Router, _activateRoute: ActivatedRoute) {
    this.__router = _router;
    this.__activateRoute = _activateRoute;

    this.initPromise();
    this.addPromise(this.__activateRoute.params, (data) => this.onRouteParams(data));
    this.addPromise(this.__activateRoute.queryParams, (data) => this.onRouteQueryParams(data));
    this.addPromise(this.__router.events, (data) => this.onRouterEvents(data));
    this.subscriberPromises();

    this.defaultRoute();
  }

  private onRouteParams(_data) {
    this.Params.nextCast(_data);
  }

  private onRouteQueryParams(_data) {
    this.QueryParams.nextCast(_data);
  }

  private onRouterEvents(_data) {
    if (_data instanceof NavigationEnd) {
      this.RouterEvents.nextCast(_data);
    }
  }

  navigateQuery(_query) {
    this.__router.navigate([], { relativeTo: this.__activateRoute, queryParams: _query });
  }

  defaultRoute() {
    this.__router.navigate([{ outlets: {} }], { relativeTo: this.__activateRoute, queryParams: this.QueryParams.Data });
  }

  getRouteArr() {
    if (this.RouterEvents.Data.url) {
      let url = this.RouterEvents.Data.url;
      let strtIndx = url.indexOf("/(");

      let endIndx = url.indexOf("//");
      if (endIndx < 0) {
        endIndx = url.indexOf(")");
      }
      return (strtIndx < 0) ? [] : url.substring(strtIndx + 2, endIndx).split("/");
    }
    return [];
  }


}