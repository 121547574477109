import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Page404Component } from './page404/page404.component';
import { HelpersModule } from './helper/helper.module';


@NgModule({
  declarations: [
    Page404Component,
  ],
  imports: [
    HelpersModule,
    RouterModule,
/*     CommonModule,
    RouterModule,
    HelpersModule,  
    FormsModule
 */  ],
  providers: [
    
  ],
  exports: [
    RouterModule,
    Page404Component,
  ],
  /* entryComponents:[ActionPenalComponent] */
})
export class SharedModule { }
