import { Injectable } from "@angular/core";
import { environment } from './../../environments/environment';

@Injectable()
export class Config {
    //private HOST = process.env.HOST || "http://10.0.0.4";
    //private PORT = process.env.PORT || 3000;
    //private API = process.env.API || 'api';

    //public BaseURL: string = "http://10.0.0.11:3002/portal/";
    public BaseURL: string =   environment.API_URL;

}