// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //API_URL: '/api/'
  //API_URL: '/portal/',
  //API_URL: "http://192.168.0.11:6002/portal/"
  //API_URL: "http://192.168.0.11:6001/portal/"
  //  API_URL: "http://192.168.0.11:6001/portal/"
  API_URL: "http://www.myslbb.com/portal/"
  //API_URL: "http://customer.visionsmartlink.com/portal/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
