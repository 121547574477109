import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private _authService: AuthService,
    private _router: Router,
    ) { 

    }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(catchError(err => {
      let path = this._router.routerState.snapshot.url;
      //console.log(path.indexOf("auth"));

      if (err.status === 401 || err.error.error === "TokenExpiredError") {
        if(path.indexOf("auth") < 0){
          this._router.navigate(["/auth/pass"]);
          //this._authService.signout();
          //location.reload(true);
        }
      }
            
      const error = err.error;
      return throwError(error);
    }));
  }
}