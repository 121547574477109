import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { httpInterceptorProviders } from './http-interceptors';
import { AuthService } from './../../auth/service/auth.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
  ],
  providers: [
    AuthService,
    httpInterceptorProviders
  ]
})
export class HelpersModule { }
