import { HttpHeaders, HttpClient, HttpHandler, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Config } from './config';
import { AlertService } from '../admin/component/notification/alert/alert.service';

const httpHeaders = new HttpHeaders({
    /* 'content-type': 'application/x-www-form-urlencoded' */
    'Content-Type': 'application/json',
    /*
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true'
    */
})
const httpOptions = {
    headers: httpHeaders
};

export class Service {
    private config: Config = new Config();
    protected _client: HttpClient;
    protected _alertservice: AlertService;
    protected _deleteObjList = [/* 'CreatedDate', 'UpdatedDate', 'Status' */];

    private __url: string = "";
    private __parentUrl: string = "";

    constructor(private _name: string, private _parentName: string = "") {
        this.__url = this.config.BaseURL + this._name.toLowerCase();
        this.__parentUrl = this.config.BaseURL + this._parentName.toLowerCase() + "/" + this._name.toLowerCase();
    }

    setRoute(name: string) {
        this.__url = this.config.BaseURL + name.toLowerCase();
    }

    getRoute() {
        return this.__url;
    }
    getAll() {
        return this._client.get<any[]>(this.__url, httpOptions)
            .pipe(
                tap(
                    data => {/* this.sucess(data, "got") */ },// this.sucess(data, "get All") ,
                    error => {//this.failure(error);
                        console.log(error);
                    }
                )
            );
    }

    getByID(id: string) {
        return this._client.get<any>(this.__url + "/" + id, httpOptions);
    }

    getByParentID(id: string) {
        return this._client.get<any>(this.__parentUrl + "/" + id, httpOptions);
    }

    getByCompanyID(id: string) {
        let cmpnyUrl = this.config.BaseURL + "company/" + id + "/" + this._name.toLowerCase();
        //console.log(cmpnyUrl);
        return this._client.get<any>(cmpnyUrl, httpOptions);
    }

    getByColumn(id: string, clmn: string) {
        return this._client.post<any>(this.__url + "/" + id + "/" + clmn, httpOptions);
    }

    getByParentName(_name: string) {
        return this._client.get<any>(this.__url + "/" + _name, httpOptions);
    }

    getViewByID(id: string) {
        return this._client.get<any>(this.__url + "/view/" + id, httpOptions);
    }
    getEditByID(id: string) {
        return this._client.get<any>(this.__url + "/edit/" + id, httpOptions);
    }

    getInvoiceByID(id: string) {
        return this._client.get<any>(this.__parentUrl + "/" + id, httpOptions);
    }
    getListByID(id: string) {
        return this._client.get<any>(this.__parentUrl + "/list/" + id, httpOptions);
    }
    post(path: string, data: any) {
        let url = this.config.BaseURL + path.toLowerCase();
        let obj = this._client.post<any[]>(url, data, httpOptions)
        console.log("url --->>>> " + url);
        console.log(JSON.stringify(obj));
        return obj;
    }

    get(path: string) {
        let url = this.config.BaseURL + path.toLowerCase();
        return this._client.get<any[]>(url, httpOptions);
    }
    importData(obj: any) {
        //console.log(obj);
 /*        for(var i=0;i<this._deleteObjList.length;i++){
            delete data[d][this._deleteObjList[i]];
        }
  */
/*         for(var d=0;d<data.length;d++){
            this._client.post<any[]>(this.url +"/import", data[d], httpOptions).pipe(
                tap(
                    data => console.log("Added"),
                    error => this.failure(error)
            )).subscribe();
        }
 */        return this._client.post<any[]>(this.__url + "/import", obj, httpOptions).pipe(
        tap(
            data => { obj = data; console.log("Added") },
            error => this.failure(error)
        ))
    }

    create(data: any) {
        for (var i = 0; i < this._deleteObjList.length; i++) {
            delete data[this._deleteObjList[i]];
        }
        return this._client.post<any[]>(this.__url, data, httpOptions).pipe(
            tap(
                data => {
                    this.sucess(data, "added");
                    return data;
                },
                error => {
                    this.failure(error);
                    return error;
                }
            )
        )
    }

    ncreate(data: any) {
        for (var i = 0; i < this._deleteObjList.length; i++) {
            delete data[this._deleteObjList[i]];
        }
        return this._client.post<any[]>(this.__url + "/new", data, httpOptions).pipe(
            tap(
                data => {
                    this.sucess(data, "added")
                    //_success(data);
                    //return data;
                },
                error => {
                    this.failure(error);
                    //_error(error);
                }
            )
        )
    }
    npost(id: string, data: any) {
        console.log(this.__parentUrl + "/" + id);
        return this._client.post<any[]>(this.__parentUrl + "/" + id, data, httpOptions).pipe(
            tap(
                data => {
                    this.sucess(data, "added")
                    //_success(data);
                    //return data;
                },
                error => {
                    this.failure(error);
                    //_error(error);
                }
            )
        )
    }
    update(data: any) {
        for (var i = 0; i < this._deleteObjList.length; i++) {
            delete data[this._deleteObjList[i]];
        }
        return this._client.put<any[]>(this.__url + "/" + data.ID, data, httpOptions).pipe(
            tap(
                data => this.sucess(data, "updated"),
                error => this.failure(error)
            )
        )
    }
    delete(_id: string) {
        return this._client.delete<any[]>(this.__url + "/" + _id, httpOptions).pipe(
            tap(
                data => this.sucess(data, "deleted"),
                error => this.failure(error)
            ))
    }

    sucess(msg, msgType) {
        //console.log("Fired");
        this._alertservice.add(this._name.toUpperCase(), "Has been " + msgType + " Sucessfully ", "Success");
    }
    failure(err) {
        console.log("Fired");
        //let msg = err.error;
        //this._alertservice.add("Error", err.error.error, "Error");
    }
}