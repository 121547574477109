export class SubscribePromise {

  sbscrbPrms: Array<any>;

  constructor() {
    this.initPromise();
  }

  initPromise() {
    this.sbscrbPrms = new Array();
  }

  addPromise(_subscribe: any, _callback: Function) {
    this.sbscrbPrms.push({
      Subscribe: _subscribe,
      Callback: _callback,
      Called: false,
      Data: null
    });
  }

  subscriberPromises() {
    clearTimeout();
    //console.log(this);
    for (let i = 0; i < this.sbscrbPrms.length; i++) {
      this.sbscrbPrms[i].Subscriber = this.sbscrbPrms[i].Subscribe.subscribe(data => {
        //console.log(data);
        this.sbscrbPrms[i].Callback(data);
        (this.hasPromise(i, data))? this.PromiseAll(): null;
      });
    }
  }
  unSubscriberPromises() {
    while(this.sbscrbPrms.length > 0) {
      let sbscrbr = this.sbscrbPrms.shift();
      sbscrbr.Subscriber.unsubscribe();
    }
    //console.log(this.sbscrbPrms);
    /* for (let i = 0; i < this.sbscrbPrms.length; i++) {
      this.sbscrbPrms[i].Subscriber.unsubscribe();
      console.log(this.sbscrbPrms[i].Subscriber);
    } */
  }

  private hasPromise(_indx, _dt) {
    for (let i = 0; i < this.sbscrbPrms.length; i++) {
      if (i === _indx) {
        this.sbscrbPrms[i].Called = true;
        this.sbscrbPrms[i].Data = _dt;
      }
      if (!this.sbscrbPrms[i].Called) {
        return false;
      }
    }
    return true;
    //this.PromiseAll();
  }

  /*   private hasPromise(_cb, _dt) {
      for (let i = 0; i < this.sbscrbPrms.length; i++) {
        if (this.sbscrbPrms[i].Callback === _cb) {
          this.sbscrbPrms[i].Called = true;
        }
        if (!this.sbscrbPrms[i].Called) {
          return false;
        }
      }
  
      this.PromiseAll();
    }
   */
  PromiseAll() {
    //console.log("Fired Process all");
    for (let i = 0; i < this.sbscrbPrms.length; i++) {
      //this.sbscrbPrms[i].Callback(this.sbscrbPrms[i].Data);
    }
  }

}
