import { ErrorHandler, Injectable, /* Injector */ } from '@angular/core';
//import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
//import { Router } from '@angular/router';

//import * as StackTraceParser from 'error-stack-parser';

//import { NotificationService } from '../../services/notification/notification.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
    constructor(
        //private injector: Injector,
        /* private _router: Router */
    ) {
        //this.callback = this._route.snapshot.queryParams['cllbck'] || '/';     
        //console.log(this._router)
    }

    handleError(error: Error | HttpErrorResponse) {
        //const notificationService = this.injector.get(NotificationService);
        //const router = this.injector.get(Router);

        if (error instanceof HttpErrorResponse) {
            // Server error happened      
            if (!navigator.onLine) {
                // No Internet connection
                console.warn('Custom Error :: No Internet Connection')
                return;// notificationService.notify();
            }
            // Http Error
            else if(error.status == 401){
                //this._router.navigate(['/auth/singup', {cllbck: '/'}]);
                console.warn("Unauthorized");
                return;
            }
            else if(error.status == 0){
                console.warn("Unreachable");
                return;
            }
            else {
                console.warn(`Custom Error :: ${error.status} - ${error.message}`);
                return;
            }
            //return "NetSoft";//notificationService.notify(`${error.status} - ${error.message}`);
        } 
        // Log the error anyway
        console.warn('Last Custom Error :: ' + error);
    }
}
