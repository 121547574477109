import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from '../../shared/service';
import { SignIn } from './auth.dto';
import { map, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends Service {

    Info;
    infoObj;

    constructor(
        protected _client: HttpClient
    ) {
        super("");
    }

    signin(signin: SignIn) {
        return this.post('auth/signin', signin).
            pipe(tap((data) => {
                if (data && data["accessToken"]) {
                    //console.log(JSON.stringify(data));
                    this.infoObj = data;
                    window.localStorage.setItem('currentUser', JSON.stringify(data));
                    if (isNullOrUndefined(this.FirstLoad))
                        this.FirstLoad = "true"
                }
                return data;
            }, (error) => console.log(error)));
    }

    signout() {
        // remove user from local storage to log user out
        window.localStorage.removeItem('currentUser');
        this.FirstLoad = "true";
    }

    /*     getToken() {
            //  this.infoObj ? console.log("getToken --->>>> " + JSON.stringify(this.infoObj["accessToken"])) : null;
            // console.log(JSON.stringify(window));
            if (isNullOrUndefined(JSON.stringify(this.infoObj)))
                return "";
            return this.infoObj.accessToken;
        }
     */
    getToken() {
        // console.log("getToken --->>>> " + JSON.stringify(this.infoObj));
        // console.log(JSON.stringify(window));
        if (window.localStorage && isNullOrUndefined(window.localStorage.getItem('currentUser')))
            return "";
        return window.localStorage ? JSON.parse(window.localStorage.getItem('currentUser')).accessToken : '';
    }

    getUserInfo() {
        if (isNullOrUndefined(window.localStorage.getItem('currentUser')))
            return "";
        return window.localStorage ? JSON.parse(window.localStorage.getItem('currentUser')).info : '';

    }

    getUserContect() {
        if (isNullOrUndefined(window.localStorage.getItem('currentUser')))
            return "";
        return JSON.parse(window.localStorage.getItem('currentUser')).info.User.Contact;

    }

    hasToken() {
        // console.log(this.getToken());
        if (this.getToken() === "")
            return false;
        return true;
    }

    set FirstLoad(_vl: any) {
        window.localStorage.setItem('firstLoad', _vl);
    }
    get FirstLoad() {
        return window.localStorage.getItem('firstLoad');
    }

    getSiteName() {
        console.log("SiteName");
        return this.get('auth/site_name');
    }
}
