import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { AuthService } from 'src/app/auth/service/auth.service';
import { Router } from '@angular/router';
import { RouteService } from '../../route.service';
import { isNullOrUndefined } from 'util';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private _authService: AuthService,
    private _routeService: RouteService,
    private _router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this._authService.getToken();
/*     console.log("----------auth----------------");
    console.log(authToken);
    console.log("----------auth----------------"); */

    let path = this._router.routerState.snapshot.url;
    //console.log(path);
    //console.log(this._routeService.QueryParams.Data);
    //console.log(path.indexOf("auth"));
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    //console.log(req.body);
    //let CompanyID = (this._routeService.QueryParams.Data) ? this._routeService.QueryParams.Data.oid : req.body;
    //console.log(bdy);
    if ((authToken != "" && path.indexOf("auth") < 0) || (path.indexOf("mobverify") >= 0 || path.indexOf("otp") >= 0 || path.indexOf("emailverify") >= 0 || path.indexOf("emailotp") >= 0)) {
  /*     console.log("----------auth nxt----------------");
      console.log(JSON.stringify(req));
      console.log("----------auth nxt----------------"); */
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken),
        /* body: {...req.body, CompanyID} */
      });
      //console.log(req);
      //return next.handle(authReq);
    }

/*     console.log("----------auth nxt----------------");
    console.log(JSON.stringify(req));
    console.log("----------auth nxt----------------"); */
    return next.handle(req);

    // send cloned request with header to the next handler.
  }
}